.admin {
  width: 100%;
  display: flex;
  overflow: hidden;

  .menu-box {
    width: 200px;
    height: 100vh;
    overflow-y: auto;
    margin-top: 60px;
  }
  .admin-main {
    margin-top: 60px;
    width: calc(100% - 200px);
    height: 100vh;
    overflow-y: auto;
    padding: 20px;
  }
}
