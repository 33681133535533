.login-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .logo {
    margin-top: 40px;
  }
  h1 {
    margin-top: 20px;
  }
  .page-login {
    .ant-tabs-bar {
      border: none;
    }

    .ant-tabs-nav-scroll {
      text-align: center;
    }

    .ant-tabs-nav-container {
      font-size: 14px;
    }

    &__others {
      color: #999;
    }

    &__icon {
      margin-left: 10px;
      font-size: 18px;
    }
  }
}
