.exam-detail {
  position: relative;
  padding: 20px;
  .time-line-box {
    position: absolute;
    left: 20px;
    top: 60px;
    h4 {
      margin-bottom: 20px;
    }
    .ant-timeline-item-last > .ant-timeline-item-content {
      max-height: none;
    }
  }
  .text-center {
    text-align: center;
  }
  .padding-top-200 {
    padding-top: 200px;
  }
}
