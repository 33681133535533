.menu-nav {
  height: 100%;
  float: right;
  li {
    display: inline-block;
    height: 100%;
    padding: 0 15px;
    font-size: 16px;
    i {
      margin-right: 5px;
    }
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    top: 16%;
  }
  .logout {
    color: #1890ff;
  }
  .logout:hover {
    color: #491f08;
  }
}
