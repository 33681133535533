.moss-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1000px;
  .moss-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
  }
}
.moss-content {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.moss-number {
  font-size: 24px;
  margin-left: 20px;
}
