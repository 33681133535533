.three {
  display: flex;
  .canvas-contains {
    width: 50vw;
    height: 100vh;
  }
  .cubes {
    margin: 10px;
    span {
      font-size: 20px;
    }
    .cube {
      display: flex;
    }
  }
}
