.answer-count-manage {
  .type-card {
    margin-bottom: 30px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
}
