.e808b2-questions {
  display: flex;
  justify-content: center;
}
.e808b2-question-box {
  display: flex;
  width: 1213px5;
  flex-wrap: wrap;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
}
.e808b2-question {
  padding: 10px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.e808b2-ans-item-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80px;
  margin: 5px 0;
  position: relative;
  left: 50px;

  &.active {
    background: #40a9ff;
    color: #ffffff;
  }

  &.right {
    background: #00ff00;
    color: #2f4f4f;
  }

  &.wrong {
    background: #ff4500;
    color: #2f4f4f;
  }
}
.e808b2-ans-item {
  width: 26px;
  line-height: 18px;
  font-size: 12px;
  cursor: pointer;
  color: #333333;
  text-align: center;
  border-radius: 4px;

  &.active {
    background: #40a9ff;
    color: #ffffff;
  }

  &.right {
    background: #00ff00;
    color: #2f4f4f;
  }

  &.wrong {
    background: #ff4500;
    color: #2f4f4f;
  }
}
