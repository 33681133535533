.answer-page {
  .title {
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
    font-weight: 700;
  }
  text-align: center;
  .footer {
    padding: 20px 0;
  }
}
