$error-color: rgb(255, 77, 79);
$error-color-alp: rgba(255, 77, 79, 0.1);

$success-color: rgb(82, 196, 26);
$success-color-alp: rgba(82, 196, 26, 0.1);

$active-color: #3370ff;
$active-color-alp: rgba(51, 112, 255, 0.1);

.group-question {
  text-align: center;
  .current-test {
    margin-top: 20px;
    color: #8f3d9d;
    weight: 600;
    font-size: 20px;
  }
  .flex-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-box {
    margin-left: 100px;
  }
  .grid {
    margin: 10px 0;
    display: grid;
    width: 360px;
    grid-template-columns: 120px 120px 120px;
    grid-template-rows: 120px 120px 120px;
    border-left: 2px solid #000000;
    border-top: 2px solid #000000;
    .grid-item {
      width: 120px;
      height: 120px;
      border-right: 2px solid #000000;
      border-bottom: 2px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      text-align: center;

      &.active {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  .answer {
    display: grid;
    width: 300px;
    grid-template-columns: 60px 60px 60px;
    grid-template-rows: 60px 60px 60px;
    border-left: 1px solid #000000;
    border-top: 1px solid #000000;
    .answer-item {
      width: 60px;
      height: 60px;
      border-right: 1px solid #000000;
      border-bottom: 1px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: bold;
      color: #333333;
      &.active {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  .result-box {
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    flex-direction: reverse;
    .res-item {
      margin: 0 10px;
      border: 2px solid rgba(0, 0, 0, 0);
      cursor: pointer;
      &.active {
        // background: rgba(0, 0, 0, 0.1);
        background: $active-color-alp;
        border-color: $active-color;
      }
      &.error {
        background: $error-color-alp;
        border-color: $error-color;
      }
      &.success {
        background: $success-color-alp;
        border-color: $success-color;
      }
    }
  }

  .footer {
    padding: 20px 0;
    .no-answer {
      padding-bottom: 10px;
      color: red;
    }
  }

  .inline-center {
    display: inline-block;
    vertical-align: middle;
  }

  // 图形变换相关操作接口
  .image-transform-rule {
    .image-transform-card {
      width: 1000px;
      margin: 0 auto 20px;
    }
    .rule-image {
      width: 40px;
      height: 40px;
      margin: 0 10px;
    }
    .rules {
      display: flex;
      flex-wrap: wrap;
      .rule-item {
        margin: 5px 0;
        width: 33%;
        display: flex;
        align-items: center;
      }
    }
    .example-box {
      margin: 10px 50px 10px 0;
    }
    .question-item {
      margin: 10px 0;
    }
    .ans-and-example {
      display: flex;
      justify-content: center;
    }
    .ans-box {
      display: flex;
    }
  }

  .block-rotate-box {
    max-width: 1300px;
    margin: 0 auto 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .answers {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .question-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    image {
      display: block;
      max-width: 800px;
    }
  }

  .answers-only-text {
    display: flex;
    justify-content: center;
    align-items: center;
    .ans-text {
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin: 20px;
      font-size: 20px;
      padding: 0 30px;
      min-width: 100px;
      font-weight: 600;
      border: 1px dashed #40a9ff;
      border-radius: 8px;
    }
    .ans-text:hover {
      background: #40a9ff;
    }
    .ans-text.active {
      background: #40a9ff;
      color: #ffffff;
    }
    .ans-text.success {
      background: $success-color;
      color: #ffffff;
    }
    .ans-text.error {
      background: $error-color;
      color: #ffffff;
    }
  }
}

.ans-item {
  margin: 0 5px 10px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  &.active {
    border-color: $active-color;
  }
  &.error {
    border-color: $error-color;
  }
  &.success {
    border-color: $success-color;
  }

  .ans-item-i {
    margin: 10px 0;
  }
}
.ans-desc {
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  border: 1px dashed #40a9ff;
  border-radius: 8px;
}
.ans-desc:hover {
  background: #40a9ff;
}
.imagess {
  display: flex;
}
.snapshots-images {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
  flex-direction: column;
  img {
    margin: 4px;
  }
}

.e808a-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 80px;

  .example {
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    p {
      text-align: center;
    }
    .example-item {
      transition: 0.3 opacity;
      opacity: 0;
      &.active {
        opacity: 1;
      }
    }
    &.e808e {
      min-height: 218px;
    }
  }
  .legend {
    text-align: center;
    padding-bottom: 10px;
  }
  .answers {
    display: flex;
    flex-wrap: wrap;
    .ans-item {
      margin-bottom: 20px;
    }
  }
  .answer-sheet {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    &.e808e {
      min-width: 1150px;
      min-height: 218px;
    }

    .e808e-hint {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #999999;
      line-height: 100px;
    }
  }
}

.margin-b-10 {
  margin-bottom: 10px;
}

.group-questions {
  display: flex;
  justify-content: center;
}
.group-question-box {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  &.e808b {
    width: 1207px;
  }
  &.e808d {
    width: 906px;
  }
}
.group-question {
  padding: 20px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.e808b-ans-item-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 160px;
  margin: 5px 0;
}
.e808b-ans-item {
  width: 70px;
  line-height: 30px;
  font-size: 20px;
  cursor: pointer;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  margin: 5px;

  &.active {
    background: #40a9ff;
    color: #ffffff;
  }

  &.right {
    background: #00ff00;
    color: #2f4f4f;
  }

  &.wrong {
    background: #ff4500;
    color: #2f4f4f;
  }
}
.group-input-number-box {
  padding-top: 10px;
  .input-number {
    text-align: center;
  }
}
