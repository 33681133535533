.whack-container {
  .whack-tool {
    position: absolute;
    pointer-events: none;
    z-index: 99999;
  }
  .run {
    animation: rotate 1s linear infinite;
  }
  .whack-content {
    display: flex;
    margin-top: 20px;
    .mole-content {
      display: flex;
      .box {
        width: 160px;
        height: 160px;
        margin: 10px 30px;
        border-radius: 12px;
        .whack-item {
          margin-top: 50px;
        }
      }
      .box-0 {
        background-color: rgb(137, 132, 217);
      }

      .box-1 {
        background-color: rgb(93, 244, 198);
      }

      .box-2 {
        background-color: rgb(230, 137, 237);
      }

      .box-3 {
        background-color: rgb(237, 225, 137);
      }
    }
  }

  .whack-result-content {
    height: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(30deg);
  }

  75% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
//
.run-shake {
  position: relative;
  animation: shake 0.32s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
