.exam-history {
  padding: 20px;
  .exam-history-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-center {
    text-align: center;
  }
}
