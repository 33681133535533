.HeaderNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #eee;
  .nav-box {
    height: 60px;
    width: 100%;
    line-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  .left {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -0.11px;
    color: #3974cc;
    a {
      color: #3974cc;
    }
    img {
      width: 26px;
      height: 26px;
      margin-right: 12px;
      margin-top: -3px;
      vertical-align: middle;
    }
  }
}
