.container {
  margin-top: 60px;
  .training-main {
    margin: 30px;
    .training-title {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-top: 40px;
      margin-bottom: 40px;
      .training-subtitle {
        margin-right: 60px;
        height: 40px;
        width: 150px;
        background-color: #2d8ee3;
        color: #fff;
        font-weight: 700;
        border-radius: 12px;
        line-height: 40px;
        text-align: center;
      }
      .hover {
        background-color: #03a9f4;
        cursor: pointer;
      }
      .disabled {
        background-color: #858e97;
        color: #e2eff5;
      }
    }
  }
  .exam-view {
    margin: 30px;
  }
  h3 {
    margin: 15px auto;
    color: #023058;
  }
}

.badge {
  margin-right: 20px;
}
.category-buttons {
  margin: 20px;
  display: flex;
  button {
    color: #fff;
    background-color: #00bcd4;
    margin-right: 24px;
    border: 0;
  }
}
