.preview-answers {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .preview-item {
    margin: 30px 3px;
  }
}
.preview-button {
  display: flex;
  justify-content: space-evenly;
}
.preview-top {
  display: flex;
  .preview-top-right {
    margin-left: 20px;
    .preview-top-right-des {
      margin-bottom: 10px;
    }
  }
}
