.operation {
  display: flex;
  justify-content: space-around;
  a {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
  }
}
